"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateOnly = exports.sleep = exports.getDistinctValues = exports.find = exports.sum = exports.roundNumber = exports.generateUUID = void 0;
exports.getMoneyString = getMoneyString;
exports.getPercentString = getPercentString;
exports.formatTimeAMPM = formatTimeAMPM;
const uuid_1 = require("uuid");
exports.generateUUID = uuid_1.v4;
const roundNumber = (num, decimals = 0) => {
    const mul = Math.pow(10, decimals);
    return Math.round(Number(num) * mul) / mul;
};
exports.roundNumber = roundNumber;
const sum = (list, prop, start = 0) => {
    return list.reduce((prev, curr) => prev + curr[prop], start);
};
exports.sum = sum;
const find = (list, predicate, thisArg) => {
    return list.filter(predicate)[0];
};
exports.find = find;
const getDistinctValues = (values, key) => {
    let distinctValues = [];
    for (let i = 0; i < values.length; i++) {
        const val = values[i][key];
        if (!distinctValues.includes(val)) {
            distinctValues.push(val);
        }
    }
    return distinctValues;
};
exports.getDistinctValues = getDistinctValues;
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
exports.sleep = sleep;
function getMoneyString(amount, digits = 0) {
    if (typeof amount === "string") {
        amount = Number(amount);
    }
    return "$" + amount.toFixed(digits);
}
function getPercentString(num, digits = 0) {
    if (isNaN(num))
        num = 0;
    return (num * 100).toFixed(digits) + "%";
}
function formatTimeAMPM(dt) {
    const numHours = dt.getHours();
    const isPM = numHours >= 12;
    const hours = numHours == 0 ? 12 : numHours > 12 ? numHours - 12 : numHours;
    return (hours.toString() +
        ":" +
        dt.getMinutes().toString().padStart(2, "0") +
        (isPM ? "PM" : "AM"));
}
const getDateOnly = (dt) => {
    return (dt.getFullYear().toString() +
        "-" +
        (dt.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        dt.getDate().toString().padStart(2, "0"));
};
exports.getDateOnly = getDateOnly;
